import { API } from "aws-amplify";
import type { FieldApi } from "@tanstack/react-form";
import { getProfile } from "@lib/queries";
// import { onError } from "../lib/errorLib";
import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";

// TODO, fix types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FieldInfo({ field }: { field: FieldApi<any, any, any, any> }) {
  return (
    <>
      {field.state.meta.isTouched && field.state.meta.errors.length ? (
        <em>{field.state.meta.errors.join(",")}</em>
      ) : null}
      {field.state.meta.isValidating ? "Validating..." : null}
    </>
  );
}

function createNewKid(name: string) {
  return API.post("kids", "/kids", {
    body: { name },
  });
}

// async function handleSubmit(name: string) {
//   // event.preventDefault();

//   // if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
//   //   alert(
//   //     `Please pick a file smaller than ${
//   //       config.MAX_ATTACHMENT_SIZE / 1000000
//   //     } MB.`
//   //   );
//   //   return;
//   // }

//   // TODO
//   // setIsLoading(true);

//   try {
//     // const attachment = file.current ? await s3Upload(file.current) : undefined;

//     await createNewKid(event);
//     // await createStickGrant({ reason: stickGrantReason, attachment });
//     // nav({ to: "/" });
//   } catch (e) {
//     onError(e);
//     // TODO
//     // setIsLoading(false);
//   }
// }

export default function Admin() {
  const query = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });

  const form = useForm({
    defaultValues: {
      name: "",
    },
    onSubmit: async ({ value }) => {
      await createNewKid(value.name);
    },
  });

  return (
    <div>
      Admin
      <h3>Here is the profile data:</h3>
      <div>{JSON.stringify(query.data)}</div>
      <h2>New Kid</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <div>
          {/* A type-safe field component*/}
          <form.Field
            name="name"
            validators={{
              onChange: ({ value }) =>
                !value
                  ? "A name is required"
                  : value.length < 3
                    ? "Name must be at least 3 characters"
                    : undefined,
              onChangeAsyncDebounceMs: 500,
              onChangeAsync: async ({ value }) => {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                return (
                  value.includes("error") && 'No "error" allowed in first name'
                );
              },
            }}
            children={(field) => {
              // Avoid hasty abstractions. Render props are great!
              return (
                <>
                  <label htmlFor={field.name}>Kid's Name:</label>
                  <input
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                  <FieldInfo field={field} />
                </>
              );
            }}
          />
        </div>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <>
              <button type="submit" disabled={!canSubmit}>
                {isSubmitting ? "..." : "Submit"}
              </button>
              <button type="reset" onClick={() => form.reset()}>
                Reset
              </button>
            </>
          )}
        />
      </form>
    </div>
  );
}
