import { Route } from "../../routes/_auth.stickGrants.$id";
import StickForm from "@components/StickForm"; // TODO better name for this component?  StickForm?
import { useNavigate } from "@tanstack/react-router";

export default function StickGrants() {
  const nav = useNavigate();

  const { id } = Route.useParams();

  return (
    <div>
      <StickForm
        onActionComplete={() => {
          nav({ to: "/home" });
        }}
        stickGrantId={id}
      />
    </div>
  );
}
