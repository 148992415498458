import React from "react";
import { Storage } from "aws-amplify";
import { useQuery } from "@tanstack/react-query";

interface AwsImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  awsImageKey: string;
}

function AwsImage({ awsImageKey, ...restProps }: AwsImageProps) {
  const result = useQuery({
    queryKey: ["awsImage", awsImageKey],
    queryFn: () => Storage.vault.get(awsImageKey),
  });
  return <img {...restProps} src={result.data} />;
}

export default AwsImage;

// Maybe this would be better (chat gpt version)
// import React from 'react';

// interface MyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
//   // You can add additional custom props here if needed
// }

// const MyImage: React.FC<MyImageProps> = (props) => {
//   return <img {...props} />;
// };

// export default MyImage;
