"use client";

import {
  CheckIcon,
  ChevronUpDownIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { classNames } from "../../utils";
import { picsByName } from "@lib/consts";
import { useKidsQuery } from "@lib/hooksLib";

export default function KidSelect({
  value,
  onChange,
  disabled = false,
}: {
  value: string | undefined; // TODO: doc the select kidId
  onChange: (value: string) => void; // TODO, check this type, assume this deals in kidIds, not kid objects
  disabled?: boolean;
}) {
  const kidsQueryResult = useKidsQuery();

  const selected =
    kidsQueryResult &&
    kidsQueryResult.data &&
    kidsQueryResult.data.find((kid) => kid.kidId === value);

  return (
    kidsQueryResult.data && (
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        <div className="relative mt-2">
          <ListboxButton
            className={classNames(
              "relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6",
              disabled ? "bg-gray-100" : "bg-white",
            )}
          >
            <span className="flex items-center">
              {selected ? (
                <img
                  alt=""
                  src={picsByName[selected?.name || ""]} // TODO, this is sloppy!
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
              ) : (
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500"></QuestionMarkCircleIcon>
              )}
              <span className="ml-3 block truncate">
                {selected ? selected.name : "No kid selected"}
              </span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </ListboxButton>

          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {kidsQueryResult.data.map((kid) => (
              <ListboxOption
                key={kid.kidId}
                value={kid.kidId} // TODO kid of kid.kidId here??
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <div className="flex items-center">
                  <img
                    alt=""
                    src={picsByName[kid.name]}
                    className="h-5 w-5 flex-shrink-0 rounded-full"
                  />
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {kid.name}
                  </span>
                </div>

                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    )
  );
}
