import {
  primaryButtonClasses,
  textInputClasses,
} from "@components/_componentStyles";

import { Auth } from "aws-amplify";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { Link } from "@tanstack/react-router";
import { classNames } from "../../utils";
import { onError } from "@lib/errorLib";
import { useAuth } from "../../auth/auth";
import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

export default function Signup() {
  const signupForm = useForm<{
    email: string;
    password: string;
    confirmPassword: string;
    confirmationCode: string;
  }>({
    onSubmit: async ({ value }) => {
      try {
        const newUser = await Auth.signUp({
          username: value.email,
          password: value.password,
        });
        // setIsLoading(false);
        setNewUser(newUser);
      } catch (e) {
        onError(e);
        // setIsLoading(false);
      }
      return null;
    },
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
    },
  });

  const confirmForm = useForm<{
    confirmationCode: string;
  }>({
    onSubmit: async ({ value }) => {
      try {
        await Auth.confirmSignUp(
          signupForm.getFieldValue("email"),
          value.confirmationCode,
        );
      } catch (e) {
        onError(e);
        // setIsLoading(false);
      }
      try {
        await auth.login(
          signupForm.getFieldValue("email"),
          signupForm.getFieldValue("confirmPassword"),
        );
      } catch (e) {
        onError(e);
        // setIsLoading(false);
      }
    },
    defaultValues: { confirmationCode: "" },
  });
  const nav = useNavigate();
  const [newUser, setNewUser] = useState<null | ISignUpResult>(null);

  function renderConfirmationForm() {
    return (
      <div className="mx-4 rounded-lg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            confirmForm.handleSubmit();
          }}
        >
          <confirmForm.Field
            name="confirmationCode"
            children={(field) => (
              <>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Confirmation Code
                </label>
                <input
                  id="confirmationCode"
                  name={field.name}
                  autoFocus
                  type="tel"
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  value={field.state.value}
                  className={classNames(textInputClasses)}
                />
              </>
            )}
          />
          <div>Please check your email for the code.</div>
          {/* </Form.Group> */}
          <confirmForm.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <button
                type="submit"
                className={classNames(primaryButtonClasses)}
                disabled={!canSubmit}
              >
                {isSubmitting ? "..." : "Verify"}
              </button>
            )}
          />
        </form>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="mx-4 rounded-lg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            signupForm.handleSubmit();
          }}
        >
          <div className="grid gap-3">
            <signupForm.Field
              name="email"
              children={(field) => (
                <>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id={field.name}
                      name={field.name}
                      className={classNames(textInputClasses)}
                      autoFocus
                      type="email"
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      autoComplete="username"
                    />
                  </div>
                </>
              )}
            />
            <signupForm.Field
              name="password"
              validators={{
                onBlur: ({ value }) => {
                  const minLength = 8;
                  const hasUpperCase = /[A-Z]/.test(value);
                  const hasLowerCase = /[a-z]/.test(value);
                  const hasNumbers = /\d/.test(value);
                  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

                  if (value.length < minLength) {
                    return "Password must be at least 8 characters long.";
                  }
                  if (!hasUpperCase) {
                    return "Password must contain at least one uppercase letter.";
                  }
                  if (!hasLowerCase) {
                    return "Password must contain at least one lowercase letter.";
                  }
                  if (!hasNumbers) {
                    return "Password must contain at least one number.";
                  }
                  if (!hasSpecialChar) {
                    return "Password must contain at least one special character.";
                  }
                  return null;
                },
              }}
              children={(field) => (
                <>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id={field.name}
                      name={field.name}
                      type="password"
                      autoComplete="new-password"
                      value={field.state.value}
                      className={classNames(textInputClasses)}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                  </div>
                  {field.state.meta.errors ? (
                    <em role="alert">{field.state.meta.errors.join(", ")}</em>
                  ) : null}
                </>
              )}
            />

            <signupForm.Field
              name="confirmPassword"
              validators={{
                // TODO make sure other password is valid before we check for a match
                onBlur: ({ value }) =>
                  value !== signupForm.getFieldValue("password")
                    ? "Passwords do not match"
                    : undefined,
              }}
              children={(field) => (
                <>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Confirm Password
                  </label>
                  <div className="mt-2">
                    <input
                      id={field.name}
                      name={field.name}
                      type="password"
                      autoComplete="new-password"
                      value={field.state.value}
                      className={classNames(textInputClasses)}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                  </div>
                </>
              )}
            />

            <signupForm.Subscribe
              selector={(state) => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => (
                <button
                  type="submit"
                  className={classNames(primaryButtonClasses)}
                  disabled={!canSubmit}
                >
                  {isSubmitting ? "..." : "Sign up"}
                </button>
              )}
            />
          </div>
        </form>
        <div className="mt-4">
          <Link to="/login" className="text-blue-700">
            Go to login
          </Link>
        </div>
      </div>
    );
  }

  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      nav({ to: "/home" });
    }
  }, [auth.user, nav]);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
        Sign up for a new account
      </h1>
      <p className="mt-4">
        Sticks For The Animals is free while in preview. Please have a look at
        the{" "}
        <a href="/privacyPolicy" className="text-blue-700">
          privacy policy
        </a>{" "}
        and feel free to sign up below. I will never share you email with
        anyone. Also, please feel free to give me your feedback. Thanks and have
        fun eh!
      </p>

      <div className="mx-auto mt-8 max-w-xl">
        {newUser === null ? renderForm() : renderConfirmationForm()}
      </div>
    </div>
  );
}
