import {
  primaryButtonClasses,
  textInputClasses,
} from "@components/_componentStyles";

import { Auth } from "aws-amplify";
import { Link } from "@tanstack/react-router";
import React from "react";
import { classNames } from "../../utils";
import { onError } from "@lib/errorLib";
import toast from "react-hot-toast";
import { useFormFields } from "@lib/hooksLib";
import { useNavigate } from "@tanstack/react-router";

export default function Login() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const nav = useNavigate();

  function validateForm() {
    return fields.email.length > 0;
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // setIsLoading(true);

    try {
      await Auth.forgotPassword(fields.email);
      nav({ to: "/reset" });
      toast.success("Please check your email.");
    } catch (error) {
      onError(error);
      // setIsLoading(false);
    }
  }

  return (
    <div className="mx-4">
      <form onSubmit={handleSubmit} className="">
        <div className="grid gap-3">
          <div className="">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                onChange={handleFieldChange}
                value={fields.email}
                className={classNames(textInputClasses)}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!validateForm()}
            className={classNames(primaryButtonClasses)}
          >
            Reset
          </button>
        </div>
      </form>
      <div className="mt-4">
        <Link to="/signup" className="text-blue-700">
          Sign up
        </Link>
      </div>
      <div className="mt-5">
        <Link to="/login" className="text-blue-700">
          Login
        </Link>
      </div>
    </div>
  );
}
