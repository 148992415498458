const Animals = () => {
  return (
    <div className="mx-auto mt-8 max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Why "For the Animals?"
        </h1>
        <div>
          Proceeds from this app, if any, will go to support an animal sanctuary
          to be chosen at a later date.
        </div>
        <div>
          Everyone cares about animals in their own way, and for many, going
          vegan is just not possible. I welcome you to support this app either
          way and know that I do not intend to keep the profits.
        </div>
        <div>
          Of all the worthy charities, I chose this angle, because animal
          suffering is rarely highlighted publicly.
        </div>
        <b>
          Animal sanctuaries provide a decent life for animals that have been
          spared from undeserved suffering and certain early death.
        </b>
        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
          For more information on the rationale behind animal activism in
          general, please see the links below (links will open in a new window):
        </h2>
        <ul role="list" className="mt-8 space-y-8 text-gray-600">
          <li className="flex gap-x-3">
            <a
              href="https://3movies.wtf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The major vegan documentaries
            </a>
          </li>
        </ul>
        <ul role="list" className="mt-8 space-y-8 text-gray-600">
          <li className="flex gap-x-3">
            <a
              href="https://youtu.be/U5hGQDLprA8?si=1LoRYiBrhjZZ99U-"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gary Yourofski's Speech
            </a>
          </li>
        </ul>
        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
          And for what to eat, instead of animal-based products, please see the
          links below. I am not affiliated, these are my legit favourites!
          (links will open in a new window):
        </h2>
        <ul role="list" className="mt-8 space-y-8 text-gray-600">
          <li className="flex gap-x-3">
            <a href="https://www.noracooks.com/">Nora Cooks</a>
          </li>
        </ul>
        <ul role="list" className="mt-8 space-y-8 text-gray-600">
          <li className="flex gap-x-3">
            <a href="https://rainbowplantlife.com/">Rainbow Plant Life</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Animals;
