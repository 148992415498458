import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useRouter } from "@tanstack/react-router";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

import { classNames } from "../../utils";
import { useAuth } from "../../auth/auth";
import { useNavigate } from "@tanstack/react-router";

const navigation = [
  { name: "Sticks List", href: "/home" },
  { name: "Kids", href: "/kids" },
];

const MainHeader = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const router = useRouter();
  const auth = useAuth();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      auth.logout().then(() => {
        router.invalidate().finally(() => {
          navigate({ to: "/" });
        });
      });
    }
  };

  return (
    <header className="bg-white">
      <Popover as="nav" aria-label="Global" className="px-4 py-6">
        {({ open, close }) => {
          return (
            <>
              <div className="mx-auto flex max-w-7xl items-center justify-between lg:px-8">
                <div className="flex flex-1">
                  <PopoverButton
                    data-testid="menu"
                    className="rounded-3xl bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-100 hover:bg-gray-50"
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    ) : (
                      <Bars3Icon aria-hidden="true" className="block h-6 w-6" />
                    )}
                  </PopoverButton>
                </div>
                <Link to="/home" className="-m-1.5 p-1.5">
                  <span className="sr-only">Sticks - For the Animals</span>
                  <img
                    alt="Sticks - For the Animals"
                    src="/sticksLogo3.svg"
                    className="mx-auto h-12 w-auto"
                  />
                </Link>
                {/* Right part of 3 (login?)*/}
                <div className="flex flex-1 justify-end"></div>
                {/* End right part */}
              </div>
              <PopoverPanel className="font-cursive absolute left-1/2 z-10 w-full max-w-xs -translate-x-1/2 transform sm:hidden">
                <div className="space-y-1 rounded bg-white shadow-lg ring-1 ring-gray-600 ring-opacity-5">
                  {navigation.map((item, i) => {
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => close()}
                        className={classNames(
                          i === 0 ? "rounded-t" : "",
                          i === navigation.length - 1 ? "rounded-b" : "",
                          "block border-l-4 border-blue-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium",
                        )}
                        activeProps={{ className: "text-blue-700" }}
                        inactiveProps={{
                          className:
                            "text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 border-transparent",
                        }}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                  {!isAuthenticated ? (
                    <Link
                      className="block w-full rounded-b py-2 pl-3 pr-4 text-base font-medium text-blue-700"
                      to="/login"
                    >
                      Log in
                    </Link>
                  ) : (
                    <button
                      onClick={async () => {
                        handleLogout();
                        close();
                      }}
                      className="block w-full rounded-b py-2 pl-3 pr-4 text-base font-medium text-blue-700"
                    >
                      Log out
                    </button>
                  )}
                </div>
              </PopoverPanel>
            </>
          );
        }}
      </Popover>
    </header>
  );
};

export default MainHeader;
