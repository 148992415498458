import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";

import { AuthContext } from "../auth/auth";
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "@tanstack/react-router";

ReactGA.initialize("G-DPYKJBLZ54"); // TODO: env

type RouterContext = {
  // TODO: change to "authentication"
  auth: AuthContext;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: function Root() {
    const location = useLocation();
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return (
      <>
        <Outlet />
        {/* <TanStackRouterDevtools /> */}
      </>
    );
  },
});
