import { createFileRoute, redirect } from "@tanstack/react-router";

import StickGrants from "../screens/StickGrants";

export const Route = createFileRoute("/_auth/stickGrants/$id")({
  component: StickGrants,
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: "/",
        },
      });
    }
  },
});
