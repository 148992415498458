import {
  primaryButtonClasses,
  textInputClasses,
} from "@components/_componentStyles";

import { Link } from "@tanstack/react-router";
import React from "react";
import { classNames } from "../../utils";
import { onError } from "@lib/errorLib";
import { useAuth } from "../../auth/auth";
import { useEffect } from "react";
import { useFormFields } from "@lib/hooksLib";
import { useNavigate } from "@tanstack/react-router";

export default function Login() {
  const auth = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (auth.user) {
      nav({ to: "/home" });
    }
  }, [auth.user, nav]);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // setIsLoading(true);

    try {
      await auth.login(fields.email, fields.password);
    } catch (error) {
      onError(error);
    }
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1>Log in here</h1>

      <div className="mx-4 mt-8 max-w-xl">
        <form onSubmit={handleSubmit} className="">
          <div className="grid gap-3">
            {/* <div controlId="email"> */}
            <div className="">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleFieldChange}
                  value={fields.email}
                  className={classNames(textInputClasses)}
                  autoComplete="username"
                />
              </div>
            </div>
            <div className="">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={handleFieldChange}
                  value={fields.password}
                  className={classNames(textInputClasses)}
                  autoComplete="password"
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={!validateForm()}
              className={classNames(primaryButtonClasses)}
            >
              Login
            </button>
          </div>
        </form>
        <div className="mt-4">
          <Link to="/signup" className="text-blue-700">
            Sign up
          </Link>
        </div>
        <div className="mt-5">
          <Link to="/forgot" className="text-blue-700">
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
}
