import {
  primaryButtonClasses,
  textInputClasses,
} from "@components/_componentStyles";

import { Auth } from "aws-amplify";
import React from "react";
import { classNames } from "../../utils";
import { onError } from "@lib/errorLib";
import { useFormFields } from "@lib/hooksLib";
import { useNavigate } from "@tanstack/react-router";

export default function ResetPassword() {
  // const { setIsAuthenticated } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    newPassword: "",
    code: "",
  });
  const nav = useNavigate();

  function validateForm() {
    return fields.code.length > 0 && fields.newPassword.length > 0;
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // setIsLoading(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.newPassword,
      );
      // setIsAuthenticated(true);
      nav({ to: "/home" });
    } catch (error) {
      onError(error);
      // setIsLoading(false);
    }
  }

  return (
    <div className="mx-4">
      <div className="mb-4">Please check your email for you reset code.</div>
      <form onSubmit={handleSubmit} className="">
        <div className="grid gap-3">
          {/* <div controlId="email"> */}
          <div className="">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                onChange={handleFieldChange}
                value={fields.email}
                className={classNames(textInputClasses)}
              />
            </div>
          </div>
          <div className="">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New Password
            </label>
            <div className="mt-2">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                onChange={handleFieldChange}
                value={fields.newPassword}
                className={classNames(textInputClasses)}
              />
            </div>
          </div>

          <div className="">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Code
            </label>
            <div className="mt-2">
              <input
                id="code"
                name="code"
                type="code"
                onChange={handleFieldChange}
                value={fields.code}
                className={classNames(textInputClasses)}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!validateForm()}
            className={classNames(primaryButtonClasses)}
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );
}
