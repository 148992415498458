import ConfirmationModal from "../ConfirmationModal";
import { useState } from "react";

export default function DeleteButton({
  objectName,
  deleteFunction,
  children,
  className,
}: {
  objectName: string;
  deleteFunction: () => Promise<null> | void; // generic instead of null?
  children: React.ReactNode;
  className: string;
}) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  return (
    <>
      <ConfirmationModal
        title="Confirm Delete"
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        onConfirm={deleteFunction}
        confirmText="Confirm"
      >
        Are you sure want to delete the {objectName}?
      </ConfirmationModal>
      <button
        type="button"
        onClick={() => setConfirmModalOpen(true)}
        className={className}
      >
        {children}
      </button>
    </>
  );
}
