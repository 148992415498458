// this should be a button that indicated indefinite progress
export default function LoaderButton({
  className = "",
  disabled = false,
  isLoading = false,
  ...props
}) {
  return (
    <button
      disabled={disabled || isLoading}
      className={className} // plus custom styling
      {...props}
    >
      {isLoading && "loading..."}
      {props.children}
    </button>
  );
}
