import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { API } from "aws-amplify";
import AddKidModal from "./components/AddKidModal";
import DeleteButton from "@components/DeleteButton";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { picsByName } from "@lib/consts";
import { queryKeys } from "@lib/consts";
import { toast } from "react-hot-toast";
import { useKidsQuery } from "@lib/hooksLib";

function Kids() {
  const kidsQueryResult = useKidsQuery();
  const [startedWithZeroKids, setStartedWithZeroKids] = useState(false);

  useEffect(() => {
    const checkForZeroKids = () => {
      if (kidsQueryResult.isFetched && kidsQueryResult.data?.length === 0) {
        // at least this once, we had 0 kids (even if they add one, we'll encourage adding more)
        setStartedWithZeroKids(true);
      }
      // if on this session we always had at least one kid, we'll make the UX for adding more kids more subtle (as they are probably done and this changes infrequently)
    };
    checkForZeroKids();
  });

  const [newKidModalOpen, setNewKidModalOpen] = useState(false);

  function getKidPicUrl(kidName: string): string {
    return picsByName[kidName] || picsByName["Other"];
  }

  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (kidId: string) => {
      return API.del("stickGrants", `/kids/${kidId}`, {});
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.KIDS });
      toast.success("Kid deleted.");
    },
    onError: () => {
      toast.error("There was an error deleting the kid. Please try again.");
    },
  });

  return (
    <div>
      <h1 className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
        Kids
      </h1>

      <AddKidModal open={newKidModalOpen} setOpen={setNewKidModalOpen} />

      <div className="m-4">
        {startedWithZeroKids && (
          <>
            <div className="flex w-full flex-row">
              <button
                className="rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => {
                  setNewKidModalOpen(true);
                }}
              >
                {kidsQueryResult.isFetched && kidsQueryResult.data?.length === 0
                  ? "Add your first kid"
                  : "Add another kid"}
              </button>
            </div>
            {kidsQueryResult.isFetched &&
              (kidsQueryResult.data?.length || 0) > 0 && (
                <p className="text-blue-600">
                  Use the menu above, or click the logo above to go the sticks
                  screen!
                </p>
              )}
          </>
        )}
        {!startedWithZeroKids && (
          <div className="flex w-full flex-row justify-end">
            <button
              className="inline-flex items-center gap-x-1.5 bg-gray-100 px-2.5 py-1.5 text-sm text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                setNewKidModalOpen(true);
              }}
            >
              <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
              New Kid
            </button>
          </div>
        )}
        <ul
          role="list"
          className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
        >
          {kidsQueryResult.isFetched &&
            kidsQueryResult.data?.map(
              (kid: { kidId: string; name: string }) => (
                <li
                  key={kid.name}
                  className="col-span-1 flex rounded-md shadow-sm"
                >
                  <img
                    alt=""
                    src={getKidPicUrl(kid.name)}
                    className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                  />
                  <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <div className="font-medium text-gray-900 hover:text-gray-600">
                        {kid.name}
                      </div>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <DeleteButton
                        objectName="Kid"
                        deleteFunction={() => deleteMutation.mutate(kid.kidId)}
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </DeleteButton>
                    </div>
                  </div>
                </li>
              ),
            )}
        </ul>
      </div>
    </div>
  );
}

export default Kids;
