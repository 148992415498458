const config = {
  STRIPE_KEY:
    "pk_test_51PtukKP2PueltzfqDaVqGNzfi4BDNRZlOb3UDVRqjeGtv3rcXZufyz7m1yfNT36H62SRY7L0gF3CaBJZnBqqgnYf00Qur2UeNi",
  // Backend config
  // Frontend config
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: import.meta.env.VITE_REGION,
    BUCKET: import.meta.env.VITE_BUCKET,
  },
  apiGateway: {
    REGION: import.meta.env.VITE_REGION,
    URL: import.meta.env.VITE_API_URL,
  },
  // stickGrantsApiGateway: {
  //   REGION: import.meta.env.VITE_REGION,
  //   URL: import.meta.env.VITE_STICK_GRANTS_API_URL,
  // },
  cognito: {
    REGION: import.meta.env.VITE_REGION,
    USER_POOL_ID: import.meta.env.VITE_USER_POOL_ID,
    APP_CLIENT_ID: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: import.meta.env.VITE_IDENTITY_POOL_ID,
  },
};

export default config;
