const APP_NAME = "Stick For The Animals";
const EMAIL_ADDRESS = "mark.degani@gmail.com";

const PrivacyPolicy = () => {
  return (
    <div className="mx-auto max-w-3xl rounded-lg bg-white p-6 shadow-md">
      <h1 className="mb-4 text-3xl font-bold">Privacy Policy</h1>
      <p className="mb-6">
        <strong>Effective Date:</strong> <span>Oct 16, 2024</span>
      </p>
      <p className="mb-4">
        Thank you for using <strong>{APP_NAME}</strong> ("we," "our," or "us").
        This privacy policy explains how we collect, use, and protect your
        information when you use our app, which is currently in preview.
      </p>
      <p className="mb-6">
        Please note that as a preview app, <strong>{APP_NAME}</strong> is still
        in development, and while we take steps to protect your privacy, we
        cannot guarantee that your data will meet the highest industry standards
        at this stage. By using our app, you acknowledge and agree to the terms
        of this privacy policy.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">1. Information We Collect</h2>
      <p className="mb-4">
        During the preview phase, we may collect the following types of
        information:
      </p>
      <ul className="mb-6 list-inside list-disc">
        <li>
          <strong>Personal Information:</strong> Information such as your name,
          email address, or phone number if you provide it during registration
          or account creation.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you interact with
          the app, including features used, session durations, crash logs, and
          performance data.
        </li>
        <li>
          <strong>Device Information:</strong> Data about the device you use to
          access the app, including the device type, operating system, and
          unique device identifiers.
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">
        2. How We Use Your Information
      </h2>
      <p className="mb-6">
        We use the information we collect for the following purposes:
      </p>
      <ul className="mb-6 list-inside list-disc">
        <li>
          To operate, maintain, and improve the app during the preview phase.
        </li>
        <li>
          To troubleshoot issues and gather feedback to enhance the user
          experience.
        </li>
        <li>To monitor app performance and usage trends.</li>
        <li>
          To communicate with you about updates, features, or technical issues
          related to the app.
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">
        3. Sharing of Your Information
      </h2>
      <p className="mb-6">
        We do not sell your personal information. However, we may share your
        information with:
      </p>
      <ul className="mb-6 list-inside list-disc">
        <li>
          <strong>Service Providers:</strong> Third-party services that help us
          with app development, analytics, and performance monitoring.
        </li>
        <li>
          <strong>Legal Compliance:</strong> If required by law or if we believe
          that disclosure is necessary to protect our rights, enforce our
          policies, or respond to legal requests.
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">4. Data Security</h2>
      <p className="mb-6">
        We are working to implement reasonable security measures to protect your
        information. However, as the app is in pre-release testing, it is
        possible that some security features may not be fully in place. We
        recommend that you avoid sharing highly sensitive information through
        the app during this phase.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">
        5. Your Rights and Choices
      </h2>
      <p className="mb-6">
        You may request to access, update, or delete your personal information
        by contacting us at {EMAIL_ADDRESS}. Please note that certain data may
        be necessary for the app’s functionality, and deleting such data may
        affect your ability to use the app.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">6. Data Retention</h2>
      <p className="mb-6">
        We will retain your information for as long as necessary to support the
        pre-release testing and improve the app. If you wish to have your data
        removed, please contact us at {EMAIL_ADDRESS}.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">
        7. Changes to This Privacy Policy
      </h2>
      <p className="mb-6">
        As the app evolves, we may update this privacy policy. We will notify
        you of any significant changes by posting the new policy within the app
        or via email. Continued use of the app after changes are made
        constitutes acceptance of the updated policy.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">8. Contact Us</h2>
      <p className="mb-6">
        If you have any questions or concerns about this privacy policy or the
        handling of your data, please contact us at {EMAIL_ADDRESS}.
      </p>

      <p className="mt-8 text-center text-sm text-gray-600">
        By using <strong>{APP_NAME}</strong> in preview, you acknowledge that
        you understand the limitations of the app in its current state,
        including potential risks to your privacy. We appreciate your feedback
        and patience as we work to improve <strong>{APP_NAME}</strong> for a
        full release.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
