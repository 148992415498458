import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";

import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { queryKeys } from "@lib/consts";
import { useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";

interface FieldsType {
  [key: string | symbol]: string;
}

export function useFormFields(
  initialState: FieldsType,
): [FieldsType, ChangeEventHandler] {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event: ChangeEvent<HTMLInputElement>) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
      return;
    },
  ];
}

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentSession();
        setIsAuthenticated(true);
      } catch (e) {
        if (e !== "No current user") {
          alert(e);
        }
      }

      setIsAuthenticating(false);
    }
    onLoad();
  }, []);

  return {
    isAuthenticated,
    isAuthenticating,
    setIsAuthenticated,
  };
}

export const useKidsQuery = () =>
  useQuery<{ name: string; kidId: string }[]>({
    queryKey: queryKeys.KIDS,
    queryFn: () => API.get("kids", "/kids", {}),
  });

export const useLogout = () => {
  const authentication = useAuth();
  const nav = useNavigate();
  return async function handleLogout() {
    await Auth.signOut();
    authentication.setIsAuthenticated(false);
    nav({ to: "/home" });
  };
};

/**
 *
 * @param kidId id of kid
 * @param placeholder string to return in place of name when kid is not found
 * @returns
 */
export const useKidDetails = (kidId: string, placeholder: string) => {
  const kidsQueryResult = useKidsQuery();
  const selectedKid = (kidsQueryResult?.data || []).find(
    (kid) => kid.kidId === kidId,
  );
  return {
    ...selectedKid,
    name: selectedKid?.name || placeholder,
  };
};

export type AuthContext = ReturnType<typeof useAuth>;
