import { API, Storage } from "aws-amplify";
import {
  primaryButtonClasses,
  secondaryButtonClasses,
  textAreaInputClasses,
  validationErrorClasses,
} from "@components/_componentStyles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import AwsImage from "@components/AwsImage";
import Datepicker from "react-tailwindcss-datepicker";
import DeleteButton from "@components/DeleteButton";
import KidSelect from "@components/KidSelect";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { StickGrantType } from "../../types/stickGrants";
import { XMarkIcon as XMarkIconMini } from "@heroicons/react/20/solid";
import { classNames } from "../../utils";
import { onError } from "@lib/errorLib";
import { queryKeys } from "@lib/consts";
import { s3Upload } from "@lib/awsLib";
import toast from "react-hot-toast";
import { useForm } from "@tanstack/react-form";
import { useKidsQuery } from "@lib/hooksLib";

/**
 * StickForm component handles the creation, editing, and deletion of stick grants.
 * It uses React Query for data fetching and mutation, and React Hook Form for form handling.
 *
 * @param {Object} props - The component props.
 * @param {function} props.onActionComplete - Callback function to be called when an action is completed, to close modal or navigate.
 * @param {string} [props.stickGrantId] - Optional ID of the stick grant to edit. If present, the form will be in edit mode and enable delete functionality.
 * @param {function} [props.setSelectedChild] - Function to set the selected child on the home screen, so that we see the sticks of the child we just gave a stick to
 *
 * @returns {JSX.Element} The rendered StickForm component.
 */
function StickForm({
  onActionComplete,
  stickGrantId,
  selectedChild,
  setSelectedChild,
}: {
  onActionComplete: (message?: string) => void;
  stickGrantId?: string;
  selectedChild?: string;
  setSelectedChild?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const queryClient = useQueryClient();

  const { data: stickGrant, isLoading: stickIsLoading } =
    useQuery<StickGrantType>({
      queryKey: queryKeys.STICK_GRANT(stickGrantId),
      queryFn: () => API.get("stickGrants", `/stickGrants/${stickGrantId}`, {}),
      enabled: !!stickGrantId,
    });

  const createMutation = useMutation({
    mutationFn: (newStickGrant: StickGrantType) => {
      return API.post("stickGrants", "/stickGrants", {
        body: newStickGrant,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.STICK_GRANTS });
      toast.success("Stick grant added successfully!", {});
    },
    onError: () => {
      toast.error("There was a error saving your stick. Please try again.");
    },
  });

  const editMutation = useMutation({
    mutationFn: (stickGrant: StickGrantType) => {
      return API.put("stickGrants", `/stickGrants/${stickGrantId}`, {
        body: stickGrant,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.STICK_GRANTS });
      toast.success("Stick grant updated!", {});
    },
    onError: () => {
      toast.error("There was a error updating your stick. Please try again.");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return API.del("stickGrants", `/stickGrants/${stickGrantId}`, {});
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.STICK_GRANTS });
      toast.success("Stick grant deleted.");
    },
    onError: () => {
      toast.error("There was an error deleting the stick. Please try again.");
    },
  });

  const form = useForm<StickGrantType>({
    onSubmit: async ({ value }) => {
      // TODO: save files
      // if (file.current && file.current.siz > config.MAX_ATTACHMENT_SIZE) {
      //   alert(
      //     `Please pick a file smaller than ${
      //       config.MAX_ATTACHMENT_SIZE / 1000000
      //     } MB.`,
      //   );
      //   return;
      // }

      if (!stickGrantId) {
        createMutation.mutate(
          { ...value, attachment: undefined },
          {
            // double onSuccess
            onSuccess: () => {
              form.reset();
              onActionComplete("Your stick was successfully added.");
              // TODO setSelectedChild, is optional, ONLY to satisfy screen that shows a child, but we should update that screen for display of selected child and make this required for this form
              if (setSelectedChild) setSelectedChild(value.kidId || "all"); // TODO there should really be a value here if SAVING (validation), so update type somewhere
            },
          },
        );
      } else {
        editMutation.mutate(
          { ...value, stickGrantId, attachment: undefined },
          {
            // double onSuccess
            onSuccess: () => {
              form.reset();
              onActionComplete("Your stick was saved.");
            },
          },
        );
      }
    },
    defaultValues: stickGrantId
      ? stickGrant
      : { kidId: selectedChild, createdAt: Number(new Date()) },
  });

  /**
   * Function to handle changes to photo selections.
   *
   * @param event
   * @returns
   */
  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files === null) return;
    // all of this is pointless and will be delete
    // const allFiles = Array.from(event.currentTarget.files).map((file) =>
    //   URL.createObjectURL(file),
    // );
    // setSelectedImages((currentFiles) => [...currentFiles, ...allFiles]);

    // real stuff:
    // we start with allowing only one photo (should indicate this on input, but for now, we just take the first)

    const selectedFile = Array.from(event.currentTarget.files)[0];
    let uploadedFile: string;

    try {
      uploadedFile = await s3Upload(selectedFile);
    } catch (e) {
      onError(e);
    }

    form.setFieldValue("attachments", (attachments) => {
      if (!attachments) {
        return [uploadedFile];
      } else {
        return [uploadedFile, ...attachments];
      }
    });
  }

  const { isLoading: kidsIsLoading } = useKidsQuery();

  return stickIsLoading || kidsIsLoading ? (
    // TODO, better loading indicator
    "Loading..."
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
    >
      <div className="h-0 flex-1 overflow-y-auto bg-gradient-to-br from-pink-50 to-blue-100">
        <div className="bg-gradient-to-b from-gray-900 to-slate-700 px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between"></div>
          <div className="mt-1">
            <p className="text-sm text-slate-100">
              Kids are amazing, aren't they? Capture it here.
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <form.Field
                name="kidId"
                validators={undefined}
                children={(field) => (
                  <>
                    <label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Kid
                    </label>
                    <div className="mt-2">
                      <KidSelect
                        value={field.state.value}
                        onChange={field.handleChange}
                        disabled={!!stickGrantId}
                      />
                    </div>
                  </>
                )}
              />
              <form.Field
                name="reason"
                validators={{
                  onChange: ({ value }) =>
                    value?.length ? undefined : "Reason is required.",
                }}
                children={(field) => (
                  <div>
                    <label
                      htmlFor={field.name}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Reason
                    </label>
                    <div className="mt-2">
                      <textarea
                        id={field.name}
                        name={field.name}
                        rows={4}
                        className={classNames(textAreaInputClasses)}
                        value={field.state.value}
                        onChange={(e) => field.handleChange(e.target.value)}
                      />
                    </div>
                    {field.state.meta.errors ? (
                      <p role="alert" className={validationErrorClasses}>
                        {field.state.meta.errors.join(", ")}
                      </p>
                    ) : null}
                  </div>
                )}
              />
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Photos
                </label>
                {/* duplicate "label" */}
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-semibold text-blue-600 hover:text-blue-500"
                >
                  <div className="mt-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-2">
                    <div className="flex justify-center">
                      <div className="flex justify-start">
                        <PhotoIcon
                          aria-hidden="true"
                          className="mx-auto h-6 text-gray-300"
                        />
                        <div className="ml-2 flex text-sm leading-6 text-gray-600">
                          <span>Add a photo</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={handleFileChange}
                            multiple
                          />
                        </div>
                        {/* <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, GIF up to 10MB
              </p> */}
                      </div>
                    </div>
                    <form.Field name="attachments" mode="array">
                      {(field) => (
                        <div className="mt-4 grid grid-cols-3 gap-4">
                          {field.state.value &&
                            field.state.value?.map((attachment) => {
                              return (
                                <div
                                  key={attachment}
                                  className="relative rounded-sm bg-white p-4"
                                >
                                  {/* TODO: this is NOT SECURE... ok for MVP, but we will generated signed urls in a lambda and store objects in S3 by user, with a policy securing objects by key, which will contain their user name */}
                                  {/* TODO: implement server-size delete logic for removing s3 objects */}
                                  <AwsImage
                                    awsImageKey={attachment}
                                    className="h-auto w-20 rounded-sm"
                                  />
                                  <div className="absolute right-0 top-0">
                                    <DeleteButton
                                      objectName="the photo"
                                      className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                                      deleteFunction={() => {
                                        // TODO, update the cache, delete the file from AWS!
                                        Storage.remove(attachment);
                                        form.setFieldValue(
                                          "attachments",
                                          (attachments) => {
                                            return attachments?.filter(
                                              (item) => item !== attachment,
                                            );
                                          },
                                        );
                                      }}
                                    >
                                      <span className="sr-only">Remove</span>
                                      <XMarkIconMini
                                        aria-hidden="true"
                                        className="h-5 w-5"
                                      />
                                    </DeleteButton>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </form.Field>
                  </div>
                </label>
              </div>

              <fieldset>
                <legend className="text-sm font-medium leading-6 text-gray-900">
                  Date of action
                </legend>
                <div className="mt-2 space-y-4"></div>
                <form.Field
                  name="createdAt"
                  children={(field) => (
                    <Datepicker
                      asSingle={true}
                      useRange={false}
                      value={{
                        startDate:
                          field.state && field.state.value
                            ? new Date(field.state.value)
                            : new Date(),
                        endDate:
                          field.state && field.state.value
                            ? new Date(field.state.value)
                            : new Date(),
                      }}
                      onChange={(date) =>
                        field.handleChange(date?.startDate?.getTime())
                      }
                      popoverDirection="up" // this should not be necessary
                    />
                  )}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end gap-2 px-4 py-5">
        {stickGrantId && (
          <DeleteButton
            deleteFunction={() => {
              form.reset();
              deleteMutation.mutate(undefined, {
                onSuccess: () => {
                  form.reset();
                  onActionComplete();
                },
              });
            }}
            className={classNames(secondaryButtonClasses, "text-red-600")}
            objectName="stick"
          >
            Delete
          </DeleteButton>
        )}
        <button
          type="button"
          onClick={() => {
            form.reset();
            onActionComplete();
          }}
          className={classNames(secondaryButtonClasses)}
        >
          Cancel
        </button>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <button
              type="submit"
              className={classNames(primaryButtonClasses)}
              disabled={!canSubmit}
            >
              {isSubmitting ? "..." : "Submit"}
            </button>
          )}
        />
      </div>
    </form>
  );
}

export default StickForm;
