// eslint-disable-next-line @typescript-eslint/no-explicit-any
import toast from "react-hot-toast";

/**
 * Handles an error by displaying an error message using a toast notification.
 *
 * @param {any} error - The error object or message to be handled. If the error is not an instance of Error but has a message property, the message property will be used.
 */
export function onError(error: unknown) {
  let message = String(error);

  if (typeof error === "object" && error !== null && "message" in error) {
    message = String((error as { message: unknown }).message);
  }

  toast.error(message);
}
