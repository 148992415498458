import { createFileRoute, redirect } from "@tanstack/react-router";

import Settings from "../../screens/Settings";

export const Route = createFileRoute("/_authenticated/settings")({
  component: Settings,
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: "/",
        },
      });
    }
  },
});
