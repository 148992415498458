"use client";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { API } from "aws-amplify";
import { CheckIcon } from "@heroicons/react/24/outline";
import { queryKeys } from "@lib/consts";
import toast from "react-hot-toast";
import { useForm } from "@tanstack/react-form";

export default function Example({
  setOpen,
  open,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}) {
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: (name: string) => {
      return API.post("kids", "/kids", {
        body: { name },
      });
    },
    onSuccess: () => {
      setOpen(false);
      form.reset();
      toast.success("Kid added successfully!", {});
      queryClient.invalidateQueries({ queryKey: queryKeys.KIDS });
    },
    onError: () => {
      toast.error(
        "Sorry, there was a error creating the kid. Please try again.",
      );
    },
  });

  const form = useForm({
    defaultValues: {
      name: "",
    },
    onSubmit: async ({ value }) => {
      createMutation.mutate(value.name);
    },
  });

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-green-600"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  New Kid
                </DialogTitle>
                <div className="mt-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      form.handleSubmit();
                    }}
                  >
                    <div>
                      {/* A type-safe field component*/}
                      <form.Field
                        name="name"
                        validators={{
                          onChange: ({ value }) =>
                            !value
                              ? "A name is required"
                              : value.length < 3
                                ? "Name must be at least 3 characters"
                                : undefined,
                          onChangeAsyncDebounceMs: 500,
                          onChangeAsync: async ({ value }) => {
                            await new Promise((resolve) =>
                              setTimeout(resolve, 1000),
                            );
                            return (
                              value.includes("error") &&
                              'No "error" allowed in first name'
                            );
                          },
                        }}
                        children={(field) => {
                          // Avoid hasty abstractions. Render props are great!
                          return (
                            <>
                              <label
                                htmlFor={field.name}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Kid's Name:
                              </label>
                              <div className="mt-2">
                                <input
                                  id={field.name}
                                  name={field.name}
                                  value={field.state.value}
                                  onBlur={field.handleBlur}
                                  onChange={(e) =>
                                    field.handleChange(e.target.value)
                                  }
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {/* <FieldInfo field={field} /> */}
                            </>
                          );
                        }}
                      />
                    </div>
                    <form.Subscribe
                      selector={(state) => [
                        state.canSubmit,
                        state.isSubmitting,
                      ]}
                      children={([canSubmit, isSubmitting]) => (
                        <>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              disabled={
                                !canSubmit ||
                                isSubmitting ||
                                createMutation.isPending
                              }
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              {isSubmitting ? "..." : "Add"}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
