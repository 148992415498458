import { API } from "aws-amplify";

export const getProfile = async () => {
  const profile = await API.get("profile", "/profile", {});
  const kids = await API.get("kids", "/kids", {});

  return {
    profile,
    kids,
  };
};

// export const createKid = () => {
//   const newKid = await API.
// }
