import { createFileRoute, redirect } from "@tanstack/react-router";

import Admin from "../../screens/Admin/Admin";

export const Route = createFileRoute("/_authenticated/admin")({
  component: Admin,
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: "/",
        },
      });
    }
  },
});
