export const ALL = "all";

// getting image urls, temporary
export const picsByName: { [key: string]: string } = {
  Claire: "https://mdegani-public.s3.amazonaws.com/ctb.jpg",
  Neil: "https://mdegani-public.s3.amazonaws.com/nmm.jpg",
  Other: "https://mdegani-public.s3.amazonaws.com/aikid.jpg",
};

export const queryKeys = {
  KIDS: ["kids"],
  STICK_GRANTS: ["stickGrants"],
  STICK_GRANT: (stickGrantId: string | undefined) => [
    "stickGrant",
    stickGrantId,
  ],
};
